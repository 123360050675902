import { graphql } from "gatsby"
import { sortBy } from "lodash"
import React, { useContext } from "react"

import Divider from "../components/Divider"
import { MetaTagsContext } from "../layouts"

import downArrow from "/src/images/arrow-down.svg"
import hammerBackground from "/src/images/code-of-conduct.svg"

const Introduction = ({
  handleDownClick,
  introductionTitle,
  introductionDescriptionLine1,
  introductionDescriptionLine2,
  introductionDescriptionLine3,
}) => (
  <div className="relative overflow-hidden bg-mvp-light-blue">
    <div
      className="hidden absolute top-0 bottom-0 left-0 right-40% transform translate-x-full
        bg-auto bg-microphone bg-no-repeat
        md:bg-cover md:block
        xl:bg-auto
        2xl:right-1/3 
        "
      style={{
        backgroundImage: `url(${hammerBackground})`,
      }}
    ></div>

    <div className="container">
      <div className="pt-10 pb-12 xl:py-24">
        <h1
          className="max-w-lg pb-6 font-light tracking-tight text-36px
          md:max-w-xs md:text-4xl md:pb-8 md:leading-snug
          lg:max-w-xs lg:text-5xl lg:leading-normal
          xl:text-7xl xl:max-w-md xl:pb-16
          2xl:text-8xl 2xl:max-w-2xl
        "
        >
          {introductionTitle}
        </h1>

        <div
          className="max-w-750px
          md:max-w-xs
          lg:text-xl lg:max-w-500px
          xl:text-2-5xl xl:max-w-screen-sm
          2xl:max-w-screen-md
        "
        >
          <div>{introductionDescriptionLine1}</div>
          <div className="py-3 lg:py-6">{introductionDescriptionLine2}</div>
          <div>{introductionDescriptionLine3}</div>
          <img
            src={downArrow}
            onClick={handleDownClick}
            className="hidden pt-10 cursor-pointer transform transition-transform duration-75 ease-in-out
              lg:block
              hover:translate-y-2
            "
          />
        </div>
      </div>
    </div>

    <div
      className="h-64 bg-cover bg-microphone
        sm:bg-microphone-small
        md:hidden"
      style={{
        backgroundImage: `url(${hammerBackground})`,
      }}
    ></div>
  </div>
)

const Rule = ({ number, title, description, isLast }) => (
  <>
    <div
      className="font-light text-center text-2xl leading-tight
      lg:text-4-5xl
    "
    >
      {number}.
    </div>
    <div
      className="col-span-11 
      md:col-span-4
    "
    >
      <div className="flex flex-col h-full">
        <div
          className="font-regular leading-tight text-2xl
          lg:text-4-5xl lg:font-light
        "
        >
          {title}
        </div>
        <div
          className="flex-grow pt-5 pb-10
          sm:text-lg
          lg:text-xl lg:pb-20
        "
        >
          {description}
        </div>

        <Divider className={`${isLast ? "hidden" : "block"} md:block`} />
      </div>
    </div>

    <div className="hidden md:block"></div>
  </>
)

const Conduct = ({ data }) => {
  const { setMetaTags } = useContext(MetaTagsContext)

  setMetaTags(data.strapiCodeOfConduct.seo)

  const conducts = sortBy(data.allStrapiConduct.edges, "node.orderNumber")

  const handleDownClick = () => {
    const conductRulesElement = document.getElementById("conduct-rules")

    conductRulesElement.scrollIntoView({
      top: 300,
      behavior: "smooth",
    })
  }

  return (
    <>
      <Introduction
        {...data.strapiCodeOfConduct}
        handleDownClick={handleDownClick}
      />

      <div className="bg-white" id="conduct-rules">
        <div className="container">
          <div
            className="grid grid-cols-12 gap-x-4 gap-y-10 py-12
            md:py-20
            lg:py-28 lg:gap-x-4 lg:gap-y-28
          "
          >
            {conducts &&
              conducts.map((conduct, index) => (
                <Rule
                  key={conduct.node.id}
                  number={conduct.node.orderNumber}
                  title={conduct.node.title}
                  description={conduct.node.description}
                  isLast={index === conducts.length - 1}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    strapiCodeOfConduct {
      introductionTitle
      introductionDescriptionLine1
      introductionDescriptionLine2
      introductionDescriptionLine3
      seo {
        title
        description
      }
    }
    allStrapiConduct {
      edges {
        node {
          title
          description
          orderNumber
        }
      }
    }
  }
`

export default Conduct
